import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  archive,
  favorite,
  listAlumni,
  removeFavorite,
  restore,
} from "../../api/alum";
import FilterButton from "../../components/Filter/FilterButton";
import {
  FormerDepartment,
  FormerSalary,
  LastKnownLocation,
  Tenure,
  TimeSinceDeparture,
} from "../../components/Filter/FilterFields";
import FilterPanel from "../../components/Filter/FilterPanel";
import Loading from "../../components/Loading";
import Menu from "../../components/OptionsMenu";
import SearchBar from "../../components/Searchbar";
import Sort from "../../components/Sort";
import ErrorToastContainer from "../../components/Toastify/ErrorToastContainer";
import { Translation } from "../../lib/constants";
import { SearchbarContextType } from "../../models/api/common";
import Button from "../../theme/Button";
import theme from "../../theme";
import AlumniDatabaseTable from "./AlumniDatabaseTable";
import {
  AlumDTO,
  AlumniDbSortEnum,
  AlumState,
  getEmploymentTypeDisplay,
} from "../../models/api/alum";
import {
  ALUMNI_DATABASE_PAGE_SIZE,
  loadAlumniDbSortFromStorage,
  MAX_SELECTED_ALUMNI,
  setAlumniDbSortInStorage,
  trackGoogleAnalyticsEvent,
} from "../../lib/utils";
import {
  AlumniDbFilterOptions,
  getAlumniDbDefaultFilters,
} from "../../models/filter";
import EllipsisText from "../../theme/EllipsisText";
import Header from "../../theme/Header";
import SuccessToastContainer from "../../components/Toastify/SuccessToastContainer";
import InfoToastContainer from "../../components/Toastify/InfoToastContainer";
import { useTableContext } from "../../components/TableView";
import SendEmailModal, { Receiver } from "../../components/SendEmailModal";
import CenterModal from "../../components/CenterModal";
import { EmailTemplateTypeEnum } from "../../models/api/resource";
import { AlumDBEvent, GAEvent } from "../../lib/eventEnums";
import { FlagsContext, UserContext } from "../../lib/context";
import { FilterMulitSelectCheckbox } from "../../components/UtilComponents.tsx";
import HeartHandIcon from "/heart-hand.svg";
import MailIcon from "/mail.svg";
import StarIcon from "/star.svg";
import MinusStarIcon from "/minus-star.svg";
import ArchiveIcon from "/archive.svg";
import RestoreIcon from "/restore.svg";
import ConfirmActionModal from "../../components/ConfirmActionModal";

export interface InputError {
  former_salary: boolean;
  tenure: boolean;
  time_since_departure: boolean;
}

export default function AlumniDatabase() {
  const { t } = useTranslation(Translation.alumniDatabase);
  const common = useTranslation(Translation.common)[0];
  const [flags] = useContext(FlagsContext);

  const showAlumniActions = () => {
    return flags?.includes("ALUMNI_FAVORITES") ?? false;
  };

  const navigate = useNavigate();

  const {
    searchTerm,
    setSearchTerm,
    page,
    setPage,
    alumniFilters,
    setAlumniFilters,
    editAlumniFilters,
    setEditAlumniFilters,
    alumni,
    setAlumni,
    firstLoadTriggered,
    setFirstLoadTriggered,
    totalRows,
    setTotalRows,
    userConfig,
    selectedAlumni,
    setSelectedAlumni,
    singleEmailReceiver,
    setSingleEmailReceiver,
    alumniToArchive,
    setAlumniToArchive,
    alumniToRestore,
    setAlumniToRestore,
  } = useTableContext();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>(
    t(loadAlumniDbSortFromStorage()),
  );
  const [sendEmailModalOpen, setSendEmailModalOpen] =
    useState<EmailTemplateTypeEnum | null>(null);
  const [alumniArchived, setAlumniArchived] = useState(false);
  const [alumniRestored, setAlumniRestored] = useState(false);

  const prevValues = useRef({
    page,
    searchTerm,
    selectedOption,
    alumniFilters,
    userConfig,
  }).current;

  const [user] = useContext(UserContext);
  const orgId = user?.orgId ?? "";

  const [inputError, setInputError] = useState<InputError>({
    former_salary: false,
    tenure: false,
    time_since_departure: false,
  });
  const updateInputError = (arg: keyof InputError) => (value: boolean) => {
    setInputError((prev) => ({
      ...prev,
      [arg]: value,
    }));
  };
  const toastContainerIds = {
    info: "alumni-database-filter-info",
    success: "alumni-database-filter-success",
    error: "alumni-database-filter-error",
  };

  const [filterOpen, setFilterOpen] = useState(false);

  const closeFilters = () => {
    setFilterOpen(false);
    setEditAlumniFilters(alumniFilters);
  };

  const applyFilters = () => {
    if (Object.values(inputError).some((value) => value)) {
      toast.error(common("filter.notifications.inputError"), {
        containerId: toastContainerIds.error,
        toastId: "filter-input-error",
      });
    } else {
      trackGoogleAnalyticsEvent({
        event: AlumDBEvent.ALUM_DB_FILTER,
        name: "filter",
        org: user?.orgName,
        customParameters: {
          editAlumniFilters,
        },
      });
      setPage(0);
      setAlumniFilters(editAlumniFilters);
      setFilterOpen(false);
    }
  };

  const sendRoleDisabled = selectedAlumni.size === 0;

  interface MenuOption {
    className: string;
    label: string;
    handler: () => void;
    hasDividerAbove: boolean;
    icon: JSX.Element;
  }

  const generateBulkMenuOptions = (
    selectedAlumni: Map<string, Receiver>,
    alumni: AlumDTO[],
  ): MenuOption[] => {
    const selectedAlumniDetails = alumni.filter((alum) =>
      selectedAlumni.has(alum.id),
    );

    const options = [
      {
        disabled: selectedAlumni.size === 0,
        className: "send-referral-request-option",
        label: t("menuOptions.sendReferralRequest.label"),
        handler: () =>
          openSendEmailModal(
            AlumDBEvent.ALUM_DB_REQUEST_REFERRAL,
            EmailTemplateTypeEnum.REFERRAL,
          ),
        hasDividerAbove: false,
        icon: <Box component="img" src={HeartHandIcon} />,
      },
      {
        disabled: selectedAlumni.size === 0,
        className: "send-message-option",
        label: t("menuOptions.sendMessage.label"),
        handler: () =>
          openSendEmailModal(
            AlumDBEvent.ALUM_DB_SEND_MESSAGE,
            EmailTemplateTypeEnum.SEND_MESSAGE,
          ),
        hasDividerAbove: false,
        icon: <Box component="img" src={MailIcon} />,
      },
    ];

    // only add favorites/archive options if feature flag is enabled
    if (showAlumniActions()) {
      const hasArchivedAlumni = selectedAlumniDetails.some(
        (alum) => alum.state === AlumState.ARCHIVE,
      );
      const hasFavoriteAlumni = selectedAlumniDetails.some(
        (alum) => alum.state === AlumState.FAVORITE,
      );
      const hasNonFavoriteAlumni = selectedAlumniDetails.some(
        (alum) => alum.state !== AlumState.FAVORITE,
      );
      const hasUnarchivedAlumni = selectedAlumniDetails.some(
        (alum) => alum.state !== AlumState.ARCHIVE,
      );

      const actionOptions = [];

      if (hasNonFavoriteAlumni) {
        const alumniToFavorite = selectedAlumniDetails.filter(
          (alum) => alum.state !== AlumState.FAVORITE,
        );
        actionOptions.push({
          disabled: selectedAlumni.size === 0,
          className: "favorite-option",
          label: t("menuOptions.favorite.label"),
          handler: () => handleAlumniFavorite(alumniToFavorite),
          hasDividerAbove: true,
          icon: <Box component="img" src={StarIcon} />,
        });
      }

      if (hasFavoriteAlumni) {
        const alumniToUnfavorite = selectedAlumniDetails.filter(
          (alum) => alum.state === AlumState.FAVORITE,
        );
        actionOptions.push({
          disabled: selectedAlumni.size === 0,
          className: "remove-favorite-option",
          label: t("menuOptions.removeFavorite.label"),
          handler: () => handleAlumniRemoveFavorite(alumniToUnfavorite),
          hasDividerAbove: !hasFavoriteAlumni,
          icon: <Box component="img" src={MinusStarIcon} />,
        });
      }

      if (hasArchivedAlumni) {
        const alumniToRestore = selectedAlumniDetails.filter(
          (alum) => alum.state === AlumState.ARCHIVE,
        );
        actionOptions.push({
          disabled: selectedAlumni.size === 0,
          className: "restore-option",
          label: t("menuOptions.restore.label"),
          handler: () => setAlumniToRestore(alumniToRestore),
          hasDividerAbove: true,
          icon: <Box component="img" src={RestoreIcon} />,
        });
      }

      if (hasUnarchivedAlumni) {
        const alumniToArchive = selectedAlumniDetails.filter(
          (alum) => alum.state !== AlumState.ARCHIVE,
        );
        actionOptions.push({
          disabled: selectedAlumni.size === 0,
          className: "archive-option",
          label: t("menuOptions.archive.label"),
          handler: () => setAlumniToArchive(alumniToArchive),
          hasDividerAbove: true,
          icon: <Box component="img" src={ArchiveIcon} />,
        });
      }

      return [...options, ...actionOptions];
    }

    return options;
  };

  const sortOptions = [
    t("sortOption.departureDateNewest"),
    t("sortOption.departureDateOldest"),
    t("sortOption.formerSalaryHighest"),
    t("sortOption.formerSalaryLowest"),
    t("sortOption.nameA-Z"),
    t("sortOption.nameZ-A"),
  ];

  const handleSortChange = (option: string) => {
    setSelectedOption(option);
    setPage(0);
  };

  useEffect(() => {
    const getSortOptionsDesc = (selectedOption: string) => {
      setAlumniDbSortInStorage(selectedOption);
      switch (selectedOption) {
        case t("sortOption.departureDateNewest"):
          return {
            sortOption: AlumniDbSortEnum.DEPARTURE_DATE,
            sortDesc: true,
          };
        case t("sortOption.departureDateOldest"):
          return {
            sortOption: AlumniDbSortEnum.DEPARTURE_DATE,
            sortDesc: false,
          };
        case t("sortOption.formerSalaryHighest"):
          return {
            sortOption: AlumniDbSortEnum.FORMER_SALARY,
            sortDesc: true,
          };
        case t("sortOption.formerSalaryLowest"):
          return {
            sortOption: AlumniDbSortEnum.FORMER_SALARY,
            sortDesc: false,
          };
        case t("sortOption.nameA-Z"):
          return {
            sortOption: AlumniDbSortEnum.NAME,
            sortDesc: false,
          };
        case t("sortOption.nameZ-A"):
          return {
            sortOption: AlumniDbSortEnum.NAME,
            sortDesc: true,
          };
        default:
          return {
            sortOption: AlumniDbSortEnum.DEPARTURE_DATE,
            sortDesc: true,
          };
      }
    };

    const fetchAlumni = async () => {
      setIsLoading(true);
      const { sortDesc, sortOption } = getSortOptionsDesc(selectedOption);
      try {
        const response = await listAlumni(
          orgId,
          page,
          ALUMNI_DATABASE_PAGE_SIZE,
          sortOption,
          sortDesc,
          searchTerm,
          alumniFilters,
        );
        setAlumni(response.alumni);
        setIsLoading(false);
        setTotalRows(response.total);
      } catch {
        toast.error(
          <>
            <span>{t("error.loadingTable.message")}</span>
            <div
              onClick={() => navigate(0)}
              style={{ marginTop: "5px", cursor: "pointer" }}
            >
              {t("error.loadingTable.buttonText")}
            </div>
          </>,
          {
            containerId: toastContainerIds.error,
          },
        );
      }
    };

    const hasUserConfigChanged = prevValues?.userConfig !== userConfig;
    const notFirstLoadAndNothingChanged =
      prevValues?.page === page &&
      prevValues?.searchTerm === searchTerm &&
      prevValues?.selectedOption === selectedOption &&
      prevValues?.alumniFilters === alumniFilters &&
      !hasUserConfigChanged &&
      !alumniArchived &&
      !alumniRestored &&
      firstLoadTriggered;
    if (notFirstLoadAndNothingChanged) return;

    if (!firstLoadTriggered || hasUserConfigChanged) {
      prevValues.userConfig = userConfig;

      setAlumniFilters(
        getAlumniDbDefaultFilters(userConfig?.org.alumEmploymentTypes),
      );
      setEditAlumniFilters(
        getAlumniDbDefaultFilters(userConfig?.org.alumEmploymentTypes),
      );
    }

    if (
      !firstLoadTriggered ||
      !hasUserConfigChanged ||
      alumniArchived ||
      alumniRestored
    ) {
      void fetchAlumni();

      prevValues.page = page;
      prevValues.searchTerm = searchTerm;
      prevValues.selectedOption = selectedOption;
      prevValues.alumniFilters = alumniFilters;
      if (alumniArchived) setAlumniArchived(false);
      if (alumniRestored) setAlumniRestored(false);
    }

    if (!firstLoadTriggered) {
      setFirstLoadTriggered(true);
    }
  }, [
    page,
    selectedOption,
    searchTerm,
    alumniFilters,
    userConfig,
    prevValues,
    firstLoadTriggered,
    setAlumniFilters,
    setEditAlumniFilters,
    setFirstLoadTriggered,
    orgId,
    setAlumni,
    setTotalRows,
    t,
    toastContainerIds.error,
    navigate,
    alumniArchived,
    alumniRestored,
  ]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleSearch = (searchTermValue: string) => {
    trackGoogleAnalyticsEvent({
      event: AlumDBEvent.ALUM_DB_SEARCH_QUERY,
      org: user?.orgName,
      type: searchTermValue,
    });
    setPage(0);
    setSearchTerm(searchTermValue);
  };

  const employmentTypeFilterLabel = userConfig?.org.alumEmploymentTypes
    ? new Map(
        userConfig?.org.alumEmploymentTypes.map((key) => [
          key,
          getEmploymentTypeDisplay(key, common),
        ]),
      )
    : new Map();

  const handleSendEmail = () => {
    setSendEmailModalOpen(null);
    toast.success(t("toast.emailSuccess"), {
      containerId: toastContainerIds.success,
    });
  };

  const openSendEmailModal = (
    event: GAEvent,
    emailType: EmailTemplateTypeEnum,
    alumni?: AlumDTO,
  ) => {
    trackGoogleAnalyticsEvent({
      event: event,
      org: user?.orgName,
      customParameters: {
        num_selected: selectedAlumni.size,
        percent_selected: selectedAlumni.size / totalRows,
      },
    });
    setSendEmailModalOpen(emailType);
    if (alumni) {
      setSingleEmailReceiver({
        id: alumni.id,
        firstName: alumni.first_name,
        lastName: alumni.last_name,
        email: alumni.email,
      });
    }
  };

  const handleCloseSendEmailModal = () => {
    setSendEmailModalOpen(null);
    setSingleEmailReceiver(null);
  };

  const handleAlumniArchive = async (alumniToArchive: AlumDTO[] | null) => {
    if (alumniToArchive === null) return;
    const currentAlumni = [...alumni]; // Save current state
    try {
      const alumniIds = alumniToArchive.map((a) => a.id);

      const newSelectedAlumni = new Map(selectedAlumni);
      alumniIds.forEach((id) => newSelectedAlumni.delete(id));
      setSelectedAlumni(newSelectedAlumni);

      await archive(orgId, alumniIds);
      setAlumniToArchive(null);
      setAlumniArchived(true);
      toast.info(
        t("menuOptions.archive.success", { count: alumniIds.length }),
        {
          containerId: toastContainerIds.info,
          toastId: "archive-success",
        },
      );
    } catch (error) {
      // Revert to original state
      setAlumni(currentAlumni);
      setTotalRows(totalRows + alumniToArchive.length);

      const restoredSelectedAlumni = new Map(selectedAlumni);
      alumniToArchive.forEach((alum) => {
        if (selectedAlumni.has(alum.id)) {
          restoredSelectedAlumni.set(alum.id, {
            id: alum.id,
            firstName: alum.first_name,
            lastName: alum.last_name,
            email: alum.email,
          });
        }
      });
      setSelectedAlumni(restoredSelectedAlumni);

      console.error(error);
    }
  };

  const handleAlumniFavorite = async (alumniToFavorite: AlumDTO[]) => {
    const currentAlumni = [...alumni];
    try {
      setAlumni(
        currentAlumni.map((a) =>
          alumniToFavorite.some((selected) => selected.id === a.id)
            ? { ...a, state: AlumState.FAVORITE }
            : a,
        ),
      );

      await favorite(
        orgId,
        alumniToFavorite.map((a) => a.id),
      );
      toast.success(
        t("toast.bulkFavoriteSuccess", {
          count: alumniToFavorite.length,
        }),
        {
          containerId: toastContainerIds.success,
        },
      );
    } catch (error) {
      setAlumni(currentAlumni);
      console.error(error);
    }
  };

  const handleAlumniRestore = async (alumniToRestore: AlumDTO[] | null) => {
    if (alumniToRestore === null) return;
    const currentAlumni = [...alumni];
    try {
      const alumniIds = alumniToRestore.map((a) => a.id);

      const newSelectedAlumni = new Map(selectedAlumni);
      alumniIds.forEach((id) => newSelectedAlumni.delete(id));
      setSelectedAlumni(newSelectedAlumni);

      await restore(orgId, alumniIds);
      setAlumniToRestore(null);
      setAlumniRestored(true);
      toast.success(
        t("menuOptions.restore.success", { count: alumniIds.length }),
        {
          containerId: toastContainerIds.success,
          toastId: "restore-success",
        },
      );
    } catch (error) {
      setAlumni(currentAlumni);
      setTotalRows(totalRows + alumniToRestore.length);

      const restoredSelectedAlumni = new Map(selectedAlumni);
      alumniToRestore.forEach((alum) => {
        if (selectedAlumni.has(alum.id)) {
          restoredSelectedAlumni.set(alum.id, {
            id: alum.id,
            firstName: alum.first_name,
            lastName: alum.last_name,
            email: alum.email,
          });
        }
      });
      setSelectedAlumni(restoredSelectedAlumni);

      console.error(error);
    }
  };

  const handleAlumniRemoveFavorite = async (alumniToUnfavorite: AlumDTO[]) => {
    const currentAlumni = [...alumni];
    try {
      setAlumni(
        currentAlumni.map((a) =>
          alumniToUnfavorite.some((selected) => selected.id === a.id)
            ? { ...a, state: null }
            : a,
        ),
      );

      await removeFavorite(
        orgId,
        alumniToUnfavorite.map((a) => a.id),
      );

      toast.info(
        t("toast.bulkRemoveFavoriteSuccess", {
          count: alumniToUnfavorite.length,
        }),
        {
          containerId: toastContainerIds.info,
        },
      );
    } catch (error) {
      setAlumni(currentAlumni);
      console.error(error);
    }
  };

  const trackSortAnalytics = (option: string) => {
    trackGoogleAnalyticsEvent({
      event: AlumDBEvent.ALUM_DB_SORT,
      org: user?.orgName,
      type: option,
    });
  };

  return (
    <Stack padding="2rem">
      <ErrorToastContainer containerId={toastContainerIds.error} />
      <SuccessToastContainer containerId={toastContainerIds.success} />
      <InfoToastContainer containerId={toastContainerIds.info} />
      <Header title={t("title")} />
      <Stack justifyContent="space-between" direction="row">
        <Stack
          direction="row"
          margin="2rem 0 1rem"
          padding="1rem 1rem 1rem 0rem"
          gap="0.75rem"
        >
          <SearchBar
            placeholder={t("searchPlaceholder")}
            onSearch={handleSearch}
            context={SearchbarContextType.ALUMNI_DATABASE}
            searchbarWidth="21.125rem"
            searchTerm={searchTerm}
            disabled={
              getNumFiltersApplied(
                alumniFilters,
                getAlumniDbDefaultFilters(userConfig?.org.alumEmploymentTypes),
              ) > 0
            }
          />
          <FilterButton
            isDisabled={isLoading || searchTerm.length > 0}
            onClick={() => setFilterOpen(true)}
            count={getNumFiltersApplied(
              alumniFilters,
              getAlumniDbDefaultFilters(userConfig?.org.alumEmploymentTypes),
            )}
          />
          <Sort
            options={sortOptions}
            selectedOption={selectedOption}
            dropdownWidth={"16.25rem"}
            onSortChange={handleSortChange}
            trackAnalyticsEvent={trackSortAnalytics}
          />
        </Stack>
        <Stack
          direction="row"
          margin="2rem 0 1rem"
          padding="1rem 1rem 1rem 0rem"
          gap="0.625rem"
        >
          {selectedAlumni.size > 0 && (
            <EllipsisText
              className="selected-count"
              fontSize="0.875rem"
              fontWeight={400}
              lineHeight="1.25rem"
              color={(theme) => theme.palette.grey[500]}
              style={{ alignSelf: "center" }}
            >
              {selectedAlumni.size}/{MAX_SELECTED_ALUMNI} {t("selected")}
            </EllipsisText>
          )}
          <Button
            buttonProps={{
              disableRipple: true,
              sx: {
                padding: "0.625rem 1rem",
                fontFamily: "inherit",
                fontSize: "0.875rem",
                fontWeight: 600,
                lineHeight: "1.25rem",
                cursor: "pointer",
                borderRadius: "0.5rem",
              },
              disabled: sendRoleDisabled,
              onClick: () =>
                openSendEmailModal(
                  AlumDBEvent.ALUM_DB_SEND_ROLE,
                  EmailTemplateTypeEnum.SEND_ROLE,
                ),
            }}
          >
            {t("sendRole")}
          </Button>
          <Box
            id="bulk-options-menu"
            sx={{
              border: `1px solid ${theme.palette.grey[200]}`,
              borderRadius: "0.5rem",
            }}
          >
            <Menu items={generateBulkMenuOptions(selectedAlumni, alumni)} />
          </Box>
        </Stack>
      </Stack>
      {isLoading ? (
        <Loading />
      ) : (
        <AlumniDatabaseTable
          alumni={alumni}
          page={page}
          pageSize={ALUMNI_DATABASE_PAGE_SIZE}
          totalRows={totalRows}
          onPageChange={handlePageChange}
          selectedAlumni={selectedAlumni}
          setSelectedAlumni={setSelectedAlumni}
          onAlumniSendRole={(alumni) =>
            openSendEmailModal(
              AlumDBEvent.ALUM_DB_SEND_ROLE,
              EmailTemplateTypeEnum.SEND_ROLE,
              alumni,
            )
          }
          onAlumniReferralRequest={(alumni) =>
            openSendEmailModal(
              AlumDBEvent.ALUM_DB_REQUEST_REFERRAL,
              EmailTemplateTypeEnum.REFERRAL,
              alumni,
            )
          }
          onAlumniSendMessage={(alumni) =>
            openSendEmailModal(
              AlumDBEvent.ALUM_DB_SEND_MESSAGE,
              EmailTemplateTypeEnum.SEND_MESSAGE,
              alumni,
            )
          }
          onAlumniArchive={(alumni) => void setAlumniToArchive([alumni])}
          onAlumniFavorite={(alumni) => void handleAlumniFavorite([alumni])}
          onAlumniRestore={(alumni) => void setAlumniToRestore([alumni])}
          onAlumniRemoveFavorite={(alumni) =>
            void handleAlumniRemoveFavorite([alumni])
          }
        />
      )}

      <FilterPanel
        description={t("filter.subtitle")}
        isOpen={filterOpen}
        closePanel={closeFilters}
        submitEnabled={true}
        onSubmit={applyFilters}
        onClear={() =>
          setEditAlumniFilters(
            getAlumniDbDefaultFilters(userConfig?.org.alumEmploymentTypes),
          )
        }
      >
        <Stack direction="column" gap="1.5rem">
          <LastKnownLocation
            initialSelectedLocations={Array.from(
              editAlumniFilters.last_known_locations,
            ).map((location) => {
              return { label: location, value: location, selectable: true };
            })}
            selectedLocations={editAlumniFilters.last_known_locations}
            setLocations={(arg: Set<string>) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                last_known_locations: arg,
              })
            }
          />
          <FormerDepartment
            initialSelectedDepartments={Array.from(
              editAlumniFilters.former_departments,
            ).map((department) => {
              return { label: department, value: department, selectable: true };
            })}
            selectedDepartments={editAlumniFilters.former_departments}
            setDepartments={(arg: Set<string>) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                former_departments: arg,
              })
            }
          />
          {editAlumniFilters.former_employment_types.size > 0 && (
            <FilterMulitSelectCheckbox
              label={common("filter.fields.labels.formerEmploymentType")}
              optionStatus={editAlumniFilters.former_employment_types}
              optionLabels={employmentTypeFilterLabel}
              setFilters={(employmentType: string, value: boolean) => {
                setEditAlumniFilters({
                  ...editAlumniFilters,
                  former_employment_types: new Map(
                    editAlumniFilters.former_employment_types,
                  ).set(employmentType, value),
                });
              }}
            />
          )}
          <FormerSalary
            minSalary={editAlumniFilters.former_salary.min}
            maxSalary={editAlumniFilters.former_salary.max}
            setMinSalary={(arg: string) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                former_salary: {
                  min: arg,
                  max: editAlumniFilters.former_salary.max,
                },
              })
            }
            setMaxSalary={(arg: string) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                former_salary: {
                  max: arg,
                  min: editAlumniFilters.former_salary.min,
                },
              })
            }
            setInputError={updateInputError("former_salary")}
          />
          <Tenure
            minTenure={editAlumniFilters.tenure.min}
            maxTenure={editAlumniFilters.tenure.max}
            setMinTenure={(arg: string) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                tenure: {
                  min: arg,
                  max: editAlumniFilters.tenure.max,
                },
              })
            }
            setMaxTenure={(arg: string) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                tenure: {
                  max: arg,
                  min: editAlumniFilters.tenure.min,
                },
              })
            }
            setInputError={updateInputError("tenure")}
          />
          <TimeSinceDeparture
            timeSince={editAlumniFilters.time_since_departure}
            setTimeSince={(arg: { years: number; months: number }) =>
              setEditAlumniFilters({
                ...editAlumniFilters,
                time_since_departure: arg,
              })
            }
            setInputError={updateInputError("time_since_departure")}
          />
        </Stack>
      </FilterPanel>
      <CenterModal open={sendEmailModalOpen != null}>
        <SendEmailModal
          onSubmit={handleSendEmail}
          onClose={handleCloseSendEmailModal}
          initialJob={null}
          emailType={sendEmailModalOpen!}
          receivers={
            singleEmailReceiver
              ? [singleEmailReceiver]
              : [...selectedAlumni.values()]
          }
          maxSelectedReceivers={MAX_SELECTED_ALUMNI}
          searchAllAlumni={true}
        />
      </CenterModal>
      <ConfirmActionModal
        open={alumniToArchive !== null}
        onClose={() => setAlumniToArchive(null)}
        primaryButtonHandler={() => void handleAlumniArchive(alumniToArchive)}
        secondaryButtonHandler={() => setAlumniToArchive(null)}
        primaryButtonBgColor={theme.palette.error.main}
        primaryButtonHoverBgColor={theme.palette.error.dark}
        heading={t("menuOptions.archive.confirmTitle")}
        subHeading={t("menuOptions.archive.confirmText")}
        primaryButtonText={t("menuOptions.archive.confirmArchive")}
        secondaryButtonText={t("menuOptions.archive.confirmCancel")}
      />
      <ConfirmActionModal
        open={alumniToRestore !== null}
        onClose={() => setAlumniToRestore(null)}
        primaryButtonHandler={() => void handleAlumniRestore(alumniToRestore)}
        secondaryButtonHandler={() => setAlumniToRestore(null)}
        primaryButtonBgColor={theme.palette.primary.main}
        primaryButtonHoverBgColor={theme.palette.primary.dark}
        heading={t("menuOptions.restore.confirmTitle")}
        subHeading={t("menuOptions.restore.confirmText")}
        primaryButtonText={t("menuOptions.restore.confirmRestore")}
        secondaryButtonText={t("menuOptions.restore.confirmCancel")}
      />
    </Stack>
  );
}

const getNumFiltersApplied = (
  filters: AlumniDbFilterOptions,
  defaultFilters: AlumniDbFilterOptions,
): number => {
  let count = 0;

  Object.keys(filters).forEach((key) => {
    switch (key as keyof AlumniDbFilterOptions) {
      case "last_known_locations":
        count += filters.last_known_locations.size;
        break;
      case "former_departments":
        count += filters.former_departments.size;
        break;
      case "former_employment_types":
        filters.former_employment_types.forEach((isChecked) => {
          if (isChecked) count++;
        });
        break;
      case "former_salary":
        if (
          filters.former_salary.min !== defaultFilters.former_salary.min ||
          filters.former_salary.max !== defaultFilters.former_salary.max
        ) {
          count++;
        }
        break;
      case "tenure":
        if (
          filters.tenure.min !== defaultFilters.tenure.min ||
          filters.tenure.max !== defaultFilters.tenure.max
        ) {
          count++;
        }
        break;
      case "time_since_departure":
        if (
          filters.time_since_departure.years !==
            defaultFilters.time_since_departure.years ||
          filters.time_since_departure.months !==
            defaultFilters.time_since_departure.months
        ) {
          count++;
        }
        break;
    }
  });

  return count;
};
