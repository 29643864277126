import { TFunction } from "i18next";
import { NameDTO } from "./common";

export type ListAlumniResponse = {
  alumni: AlumDTO[];
  total: number;
};

export type AlumDTO = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  linkedin_profile_url: string | null;
  profile_photo_url: string | null;
  org_name: string;
  end_date_at_org: string;
  tenure_at_org: number | null;
  job_matches: JobMatchDTO[];
  job_history: JobHistoryDTO[];
  former_employment_type: string | null;
  last_job_title_at_org: string | null;
  last_job_department_at_org: string | null;
  former_salary: number;
  former_salary_pay_period: string | null;
  current_job_title: string | null;
  current_company: string | null;
  last_known_location: string;
  last_time_contacted: string | null;
  would_rehire: boolean | null;
  reason_for_dismissal: string | null;
  is_subscribed: boolean;
  company_contacts: CompanyContactDTO[];
  education_history: EducationHistoryDTO[];
  notes: NoteDTO[];
  state: AlumState | null;
};

export type AlumProfileAutocompleteResponse = {
  suggestions: string[];
};

export type AlumProfileAutocompleteByNameResponse = {
  alumni: AlumDTO[];
};

export type JobMatchDTO = {
  job_id: string;
  title: string;
  department: string;
  locations: string[];
  recruiters: NameDTO[];
  date_posted: string | null;
  notable_feature: string;
  notable_feature_tag: string;
  status: string;
  status_modified_date: string;
  emails_sent: number;
  requisition_id: string;
};

export type NoteDTO = {
  id: string;
  content: string;
  created_date: string;
  created_by: AuthorDTO;
};

export type AuthorDTO = {
  first_name: string;
  last_name: string;
  email: string;
  org_id: string;
  org_name: string;
};

export type CompanyContactDTO = {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  relationship_type: RelationshipTypeEnum;
  current_role: string | null;
  role_during_crossover: string | null;
  time_since_crossover: number;
  profile_photo_url: string | null;
};

export type JobHistoryDTO = {
  title: string;
  start_date: string | null;
  end_date: string | null;
  org_logo_url: string | null;
  org_name: string;
  location: string;
  department: string | null;
  manager: NameDTO | null;
  is_from_hris: boolean;
  is_from_requesting_org: boolean;
  employment_type: string | null;
};

export type EducationHistoryDTO = {
  school_name: string;
  start_date: string | null;
  end_date: string | null;
  degree: string | null;
  major: string | null;
  minor: string | null;
};

export enum AlumState {
  FAVORITE = "FAVORITE",
  ARCHIVE = "ARCHIVE",
}

export enum RelationshipTypeEnum {
  MANAGER = "MANAGER",
  DIRECT_REPORT = "DIRECT_REPORT",
}

export enum EmploymentTypeEnum {
  FULL_TIME = "FULL_TIME",
  PART_TIME = "PART_TIME",
  INTERN = "INTERN",
  CONTRACTOR = "CONTRACTOR",
  FREELANCE = "FREELANCE",
}

export enum AlumniDbSortEnum {
  DEPARTURE_DATE = "DEPARTURE_DATE",
  FORMER_SALARY = "FORMER_SALARY",
  NAME = "NAME",
}

export const getEmploymentTypeDisplay = (
  employmentType: string | null,
  t: TFunction,
) => {
  switch (employmentType) {
    case EmploymentTypeEnum.FULL_TIME:
      return t("employmentType.fullTime");
    case EmploymentTypeEnum.PART_TIME:
      return t("employmentType.partTime");
    case EmploymentTypeEnum.INTERN:
      return t("employmentType.intern");
    case EmploymentTypeEnum.CONTRACTOR:
      return t("employmentType.contractor");
    case EmploymentTypeEnum.FREELANCE:
      return t("employmentType.freelance");
    case null:
      return "";
    default:
      return employmentType;
  }
};
