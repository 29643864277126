import { useContext, useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import {
  AlumniDbFilterOptions,
  getAlumniDbDefaultFilters,
} from "../../models/filter";
import { AlumDTO } from "../../models/api/alum";
import { JobDashboardDTO, JobAlumniMatchDTO } from "../../models/api/job";
import { JobFilterOptions, DEFAULT_JOB_FILTERS } from "../../models/filter";
import { UserConfigContext } from "../../lib/context";
import { UserConfigDTO } from "../../models/config";
import { Receiver } from "../SendEmailModal";

interface TableContext {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  page: number;
  setPage: (page: number) => void;
  alumniFilters: AlumniDbFilterOptions;
  setAlumniFilters: (filters: AlumniDbFilterOptions) => void;
  editAlumniFilters: AlumniDbFilterOptions;
  setEditAlumniFilters: (filters: AlumniDbFilterOptions) => void;
  jobFilters: JobFilterOptions;
  setJobFilters: (filters: JobFilterOptions) => void;
  editJobFilters: JobFilterOptions;
  setEditJobFilters: (filters: JobFilterOptions) => void;
  alumni: AlumDTO[];
  setAlumni: (alumni: AlumDTO[]) => void;
  firstLoadTriggered: boolean;
  setFirstLoadTriggered: (triggered: boolean) => void;
  totalRows: number;
  setTotalRows: (totalRows: number) => void;
  jobs: JobDashboardDTO[];
  setJobs: (jobs: JobDashboardDTO[]) => void;
  jobMatches: JobAlumniMatchDTO[];
  setJobMatches: (jobMatches: JobAlumniMatchDTO[]) => void;
  selectedAlumni: Map<string, Receiver>;
  setSelectedAlumni: (selectedAlumni: Map<string, Receiver>) => void;
  singleEmailReceiver: Receiver | null;
  setSingleEmailReceiver: (receiver: Receiver | null) => void;
  selectedIds: Set<string>;
  setSelectedIds: (selectedIds: Set<string>) => void;
  lastJobId: string;
  setLastJobId: (lastJobId: string) => void;
  userConfig: UserConfigDTO | null;
  alumniToArchive: AlumDTO[] | null;
  setAlumniToArchive: (archived: AlumDTO[] | null) => void;
  alumniToRestore: AlumDTO[] | null;
  setAlumniToRestore: (restored: AlumDTO[] | null) => void;
}

export default function TableView() {
  // These values are used to store the state of the table. A table view is mounted each time we visit a table view page.
  // This allows use to store the state of the table view for each table view page.
  // These values are then passed to the child table to manipulate the table state.
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [alumniFilters, setAlumniFilters] = useState<AlumniDbFilterOptions>(
    getAlumniDbDefaultFilters,
  );
  const [editAlumniFilters, setEditAlumniFilters] =
    useState<AlumniDbFilterOptions>(getAlumniDbDefaultFilters);
  const [jobFilters, setJobFilters] =
    useState<JobFilterOptions>(DEFAULT_JOB_FILTERS);
  const [editJobFilters, setEditJobFilters] =
    useState<JobFilterOptions>(DEFAULT_JOB_FILTERS);
  const [alumni, setAlumni] = useState<AlumDTO[]>([]);
  const [firstLoadTriggered, setFirstLoadTriggered] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [jobs, setJobs] = useState<JobDashboardDTO[]>([]);
  const [jobMatches, setJobMatches] = useState<JobAlumniMatchDTO[]>([]);
  const [selectedIds, setSelectedIds] = useState(new Set<string>());
  const [selectedAlumni, setSelectedAlumni] = useState(
    new Map<string, Receiver>(),
  );
  const [singleEmailReceiver, setSingleEmailReceiver] =
    useState<Receiver | null>(null);
  const [lastJobId, setLastJobId] = useState<string>("");
  const [userConfig] = useContext(UserConfigContext);
  const [alumniToArchive, setAlumniToArchive] = useState<AlumDTO[] | null>(null)
  const [alumniToRestore, setAlumniToRestore] = useState<AlumDTO[] | null>(null)

  return (
    <Outlet
      context={
        {
          searchTerm,
          setSearchTerm,
          page,
          setPage,
          alumniFilters,
          setAlumniFilters,
          editAlumniFilters,
          setEditAlumniFilters,
          jobFilters,
          setJobFilters,
          editJobFilters,
          setEditJobFilters,
          alumni,
          setAlumni,
          firstLoadTriggered,
          setFirstLoadTriggered,
          totalRows,
          setTotalRows,
          jobs,
          setJobs,
          jobMatches,
          setJobMatches,
          selectedIds,
          setSelectedIds,
          selectedAlumni,
          setSelectedAlumni,
          singleEmailReceiver,
          setSingleEmailReceiver,
          lastJobId,
          setLastJobId,
          userConfig,
          alumniToArchive,
          setAlumniToArchive,
          alumniToRestore,
          setAlumniToRestore
        } satisfies TableContext
      }
    />
  );
}

export function useTableContext() {
  return useOutletContext<TableContext>();
}
