import Box from "@mui/material/Box";
import Stack, { StackProps } from "@mui/material/Stack";
import EllipsisText from "./EllipsisText";
import { useEffect, useState, useContext, ReactNode } from "react";
import InitialsIcon from "./InitialsIcon";
import GoldStarIcon from "/gold-star.svg";
import linkedinIcon from "/linkedin.svg";
import { AlumProfileEvent } from "../lib/eventEnums";
import { buildUrl, trackGoogleAnalyticsEvent, Format } from "../lib/utils";
import { UserContext } from "../lib/context";
import { useTranslation } from "react-i18next";
import { Translation } from "../lib/constants";
import Typography from "@mui/material/Typography";
import { FormattedText, FormattedTextType } from "./FormattedText";
import Tooltip from "./Tooltip.tsx";
import theme from "./index.ts";
import { AlumState } from "../models/api/alum.ts";

interface AvatarProps {
  firstName: string;
  lastName: string;
  email: string;
  location?: string | null;
  size: AvatarSize;
  photoUrl?: string | null;
  linkedInUrl?: string | null;
  tag?: ReactNode;
  stackProps?: StackProps;
  isSubscribed?: boolean;
  isDisabled?: boolean;
  callToAction?: ReactNode;
  alumState?: AlumState | null;
  showFavorite?: boolean | null;
}

export default function Avatar(props: AvatarProps) {
  const { t } = useTranslation(Translation.common);
  const {
    firstName,
    lastName,
    email,
    location,
    size,
    photoUrl,
    linkedInUrl,
    isSubscribed,
    alumState,
    showFavorite,
    tag,
    isDisabled,
    stackProps,
    callToAction,
  } = props;
  const [imageError, setImageError] = useState(false);
  const [user] = useContext(UserContext);

  useEffect(() => {
    setImageError(false);
  }, [photoUrl]);

  const handleLinkedInClick = () => {
    if (user?.orgId) {
      trackGoogleAnalyticsEvent({
        event: AlumProfileEvent.ALUM_PROFILE_LINKEDIN,
        type: "linkedin_click",
        org: user?.orgName,
      });
    }
  };

  let nameFontSize = "";
  let emailFontSize = "";
  let locationFontSize = "";
  let profileIconFontSize = "";
  let iconSize = "";
  let imageNameGap = "";
  let optedOutTextStyle = {};

  switch (size) {
    case AvatarSize.SMALL:
      nameFontSize = "0.75rem";
      emailFontSize = "0.75rem";
      locationFontSize = "0.75rem";
      profileIconFontSize = "0.875rem";
      iconSize = "2.25rem";
      imageNameGap = "0.75rem";
      break;
    case AvatarSize.MEDIUM:
      nameFontSize = "0.875rem";
      emailFontSize = "0.875rem";
      locationFontSize = "0.875rem";
      profileIconFontSize = "0.875rem";
      iconSize = "2.5rem";
      imageNameGap = "0.75rem";
      optedOutTextStyle = {
        display: "block",
        fontSize: emailFontSize,
      };
      break;
    case AvatarSize.LARGE:
      nameFontSize = "1.5rem";
      emailFontSize = "1rem";
      locationFontSize = "1rem";
      profileIconFontSize = "1.5rem";
      iconSize = "5rem";
      imageNameGap = "1rem";
      optedOutTextStyle = {
        display: "inline",
        marginLeft: "1rem",
        fontSize: emailFontSize,
      };
      break;
  }

  return (
    <Stack
      id="avatar"
      flexDirection="row"
      gap={imageNameGap}
      alignItems="center"
      {...stackProps}
    >
      <Box flexShrink={0} flexGrow={0}>
        {photoUrl && !imageError ? (
          <Box
            width={iconSize}
            height={iconSize}
            borderRadius="50%"
            overflow="hidden"
          >
            <Box
              component="img"
              alt="profile-photo"
              src={photoUrl}
              width="100%"
              height="100%"
              onError={() => setImageError(true)}
            />
          </Box>
        ) : (
          <InitialsIcon
            firstName={firstName}
            lastName={lastName}
            size={iconSize}
            fontSize={profileIconFontSize}
          />
        )}
      </Box>
      <Stack
        width={`calc(100% - ${iconSize} - ${imageNameGap})`}
        flexShrink={0}
        flexGrow={0}
        gap="1.5rem"
      >
        <Stack gap="0.25rem">
          <Stack
            direction="row"
            alignItems="center"
            gap="0.25rem"
            color={(theme) => {
              if (isDisabled) {
                return theme.palette.grey[400];
              }
              return theme.palette.grey[800];
            }}
          >
            <EllipsisText
              fontSize={nameFontSize}
              fontWeight={size === AvatarSize.LARGE ? 600 : 400}
              color="inherit"
              alignItems="center"
              gap="0.5rem"
            >
              {firstName} {lastName}
            </EllipsisText>
            {isSubscribed === false && size === AvatarSize.LARGE && (
              <>
                <Typography fontSize="2rem">&middot;</Typography>
                <EllipsisText
                  component="span"
                  fontSize="1rem"
                  fontWeight={400}
                  color={(theme) => theme.palette.grey[400]}
                >
                  {t("optedOut")}
                </EllipsisText>
              </>
            )}
            {showFavorite && alumState === AlumState.FAVORITE && (
              <Tooltip title={t("favorited")}>
                <Box
                  className="favoriteIcon"
                  component="img"
                  src={GoldStarIcon}
                  alt="favorite"
                  width="1rem"
                  height="1rem"
                />
              </Tooltip>
            )}
            {showFavorite && alumState === AlumState.ARCHIVE && (
              <Box bgcolor={theme.palette.grey[500]} borderRadius="0.375rem">
                <FormattedText
                  props={{
                    value: t("archived"),
                    type: FormattedTextType.PRIMARY,
                  }}
                  style={{
                    padding: "0.25rem 0.5rem",
                    color: theme.palette.common.white,
                  }}
                />
              </Box>
            )}
            {tag && <Box paddingRight="1.5rem">{tag}</Box>}
            {callToAction && <Box marginLeft="auto">{callToAction}</Box>}
          </Stack>
          <FormattedText
            props={{
              value: email,
              type: FormattedTextType.SECONDARY,
            }}
            style={{
              fontSize: emailFontSize,
              paddingRight: "1.5rem",
              color: (theme) => {
                if (isDisabled) {
                  return theme.palette.grey[400];
                } else if (size === AvatarSize.LARGE) {
                  return theme.palette.grey[800];
                }
                return theme.palette.grey[500];
              },
            }}
          />
          {location && (
            <FormattedText
              props={{
                value: Format.location(location),
                type: FormattedTextType.SECONDARY,
              }}
              style={{
                fontSize: locationFontSize,
                paddingRight: "1.5rem",
                color: (theme) => {
                  return theme.palette.grey[500];
                },
              }}
            />
          )}
          <></>
          {size !== AvatarSize.LARGE && isSubscribed === false && (
            <EllipsisText
              fontSize={emailFontSize}
              fontWeight={400}
              color={(theme) => theme.palette.grey[400]}
              style={optedOutTextStyle}
            >
              {t("optedOut")}
            </EllipsisText>
          )}
        </Stack>
        {linkedInUrl ? (
          <Box
            component="a"
            href={buildUrl(linkedInUrl)}
            target="_blank"
            onClick={handleLinkedInClick}
            width="2rem"
            height="2rem"
          >
            <Box
              component="img"
              src={linkedinIcon}
              width="2rem"
              height="2rem"
            />
          </Box>
        ) : null}
      </Stack>
    </Stack>
  );
}

export enum AvatarSize {
  SMALL = "sm",
  MEDIUM = "md",
  LARGE = "lg",
}
